.userTab{
    flex-grow: 1;
    padding: 8px 10px;
    padding-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
}
.tabTitle{
    font-size: 14px;
    font-weight: 500; 
}
.userTab_active{
    flex-grow: 1;
    padding: 8px 10px;
    padding-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
    background-color: rgba(12, 118, 138, 0.3) !important;
    color: rgb(12, 118, 138) !important;
    border-bottom: 2px solid rgb(12, 118, 138) !important;
}
textarea:focus{
    outline: none !important;
}