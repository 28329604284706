.noti_ico {
    padding: 8px;
    font-size: 30px;
    background-color: #0C768A;
    border-radius: 50%;
    color: #fff;
    margin-top: 4px;
}

.notification_wrapper {
    padding: 15px 15px;
    cursor: pointer;
    transition: 0.3s;
}

.notification_wrapper:hover {
    background-color: rgba(4, 4, 4, 0.04);
    transition: 0.3s;
}