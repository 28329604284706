.sidebar{
    width: 250px;
    height: 100vh;
    background-color: #0C768A;
    position: fixed;
}
.logowrapper{
    padding: 9px 0px;
    height: 10vh;
}
.logowrapper img{
    width: 140px;
    margin-left: 20px;
}
.reportingWrapper{
    margin: 11px 15px;
    background-color: rgba(255,255,255,.15);
    padding: 6px 10px;
    border-radius: 4px;
}
.reportingTitle{
    color: #fff;
    font-weight: 450;
    line-height: 18px;
    font-size: 14px;
}
.reportingName{
    color: rgba(255,255,255,.6)!important;
    font-size: 13px;
}
.menuWrapper{
    height: 71vh;
    
}
.sidebarBottom{
    margin: 12px 20px;
    height: 10vh;
}
.menu-title{
    padding: 12px 20px;
    letter-spacing: .05em;
    pointer-events: none;
    /* cursor: default; */
    font-size: 11px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-family: Rubik,sans-serif;
    opacity: .5;
}

.menu-item{
    width: 240px;
    padding: 12px 20px;
    letter-spacing: .02em;
    cursor: pointer !important;
    font-size: 13.5px;
    color: rgba(255,255,255,.7);
    font-weight: 400;
    font-family: Rubik,sans-serif;
    margin-left: 5px;
    transition: 0.3s;
}
.menu-item2{
    width: 225px;
    padding: 12px 20px ;
    letter-spacing: .02em;
    cursor: pointer !important;
    font-size: 13.5px;
    color: rgba(255,255,255,.7);
    font-weight: 400;
    font-family: Rubik,sans-serif;
    margin-left: 5px;
    transition: 0.3s;
}
.menu-active{
    transition: 0.3s;
    color: #fff !important;
}

.offcanvas.offcanvas-start{
    width: 250px !important;
    height: 100vh;
}

@media (max-width:850px) {
    .menuWrapper{
        height: 74vh;
    }
}