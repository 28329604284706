.layoutWrapper{
    display: flex;
    flex-direction: row;
}
.main-body{
   min-height: 100vh;
   margin-left: 250px;
   width: 100%;
   transition: 0.3s;
}

.content-wrapper{
    padding: 0px 25px;
}
.resize_none{
    resize: none;
}

@media (max-width:850px) {
    .main-body{
        margin-left: 0px !important;
    }
    .content-wrapper{
        padding: 0px 10px;
    }
}