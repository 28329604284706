.category_body {
    padding: 1.3rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px;
    border-radius: 2px;
    transition: 0.3s;
}

.localSearchInput{
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 30px;
    font-size: 13px;
    transition: 0.3s;
    width: 100%;
}

.table {
    --bs-table-bg: inherit !important;
    vertical-align: top !important;
}

thead th {
    font-size: 11px;
    font-weight: 400;
    border-bottom: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
    padding: 0.9rem 0.6rem !important;
    transition: 0.3s;
    text-transform: uppercase;
}

tbody td {
    vertical-align: top !important;
    font-size: 14px;
    padding: 0.9rem 0.6rem !important;
    transition: 0.3s;
    font-weight: 400 !important;
    letter-spacing: 0.2px;
}

.table .form-check-input {
    font-size: 16px !important;
}

.modal_title{
    font-size: 16px !important;
    font-weight: 450 !important;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    word-spacing: 2px;
}

.modal_close{
    opacity: 0.5;
    transition: 0.3s;
    font-size: 20px !important;
}

.modal_close:hover{
    opacity: 0.9;
    transition: 0.3s;
}

.modal_label{
    font-size: 13px;
    padding-bottom: 4px;
    font-weight: 450;
}

.modal_input{
    width: 100%;
    padding: 10px 8px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    font-size: 14px;
    transition: 0.3s;
}
.calculator_input{
    display: block;
    width: 100%;
    padding: 6px 8px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    font-size: 14.5px;
    transition: 0.3s;
}

@media (max-width:768px) {
    .ico-search{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .category_body{
        padding: 1rem 0.8rem !important;
    }
    .calculator_input{
        width: 100%;
    }
}
