.primaryButton {
    border: none;
    padding: 7px 12px;
    background-color: #0C768A;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.primaryButton:hover {
    transition: 0.3s;
    background-color: #0b6d7f;
}

.lightButton {
    border: none;
    padding: 7px 12px;
    background-color: transparent;
    /* color: #000; */
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.lightButton:hover {
    transition: 0.3s;
    background-color: rgba(223, 226, 227, 0.5);
}

.successButton {
    border: none;
    padding: 7px 12px;
    background-color: #38c786;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.successButton:hover {
    transition: 0.3s;
    background-color: #34b77b;
}

.infoButton {
    border: none;
    padding: 7px 12px;
    background-color: #4e9def;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.infoButton:hover {
    transition: 0.3s;
    background-color: #4890dc;
}

.warningButton {
    border: none;
    padding: 7px 12px;
    background-color: #f4ba40;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.warningButton:hover {
    transition: 0.3s;
    background-color: #e0ab3b;
}

.dangerButton {
    border: none;
    padding: 7px 12px;
    background-color: #ed5e49;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.dangerButton:hover {
    transition: 0.3s;
    background-color: #da5643;
}

.darkButton {
    border: none;
    padding: 7px 12px;
    background-color: #47484a;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.darkButton:hover {
    transition: 0.3s;
    background-color: #565758;
}

.secondaryButton {
    border: none;
    padding: 7px 12px;
    background-color: #8590a5;
    color: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 3px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    text-align: center;
}

.secondaryButton:hover {
    transition: 0.3s;
    background-color: #7a8498;
}

/* --------------pagination CSS start--------------------- */
.pagination li{
    list-style: none !important;
}
.pagination {
    font-family: 'Rubik', sans-serifm !important;
    color: #0b6d7f !important;
    justify-content: left;
    background-color: transparent;
    padding: 10px 5px;
    text-align: left !important;
    overflow: auto;
    /* border-collapse: collapse; */
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
}

.pagination-active {
    /* background: rgba(224, 168, 13, 1) !important; */
    background-color: #0b6d7f !important;
    color: #fff;
    justify-content: center;

}

.page-link {
    color: inherit !important;
    background-color: inherit !important;
    border: 0.5px solid rgba(151, 152, 152, 0.5) !important;
    font-weight: 350 !important;
    font-size: 1rem!important;
    padding: 12px 17px !important;
}

.page-item.disabled {
    opacity: 0.5 !important;

}

.page-link:focus {
    box-shadow: none !important;
}
.ql-editor {
    min-height: 300px!important;
}

.react-toast-notifications__container{
    z-index: 999999999999 !important;
}

@media (max-width:850px) {
    .primaryButton,
    .successButton,
    .dangerButton,
    .darkButton,
    .infoButton,
    .lightButton,
    .secondaryButton,
    .warningButton {
        font-size: 13px;
        padding: 6px 15px;
    }
}

.react-toast-notifications__container {
    z-index: 999999999999 !important;
}

#style-3::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: transparent;
}

#style-3::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 16px;
    /* background-color: rgba(0, 0, 0, 0.2); */
    background-color: #949393;
    margin-right: 1px;
    margin-top: 2px;
    margin-bottom: 2px;
}

/* -------------------Filter Csss-------------------------------- */

.filter_values_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 30px;
}

.filter_values {
    background-color: rgba(255, 87, 48, 0.1);
    color: #ff5730;
    border: 0.5px solid rgba(255, 87, 48, 0.7);
    font-size: 11px;
    padding: 2px 10px;
    padding-top: 4px;
    border-radius: 16px;
    margin-top: 10px;
    margin-right: 15px;
    text-transform: capitalize;
}

.filter_values_title {
    width: 100%;
    font-size: 13px;
    font-weight: 600;

}

.clear_filter_link {
    font-size: 12px;
    color: #107DAC;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.modal{
    z-index: 9999999 !important;
}
.offcanvas{
    z-index: 9999999 !important;
}
.modal-dialog{
    z-index: 9999999 !important;
}
.modal-content{
    z-index: 9999999 !important;
}