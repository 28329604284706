.userPermissionWrapper{
    margin: 40px 0px;
}

.per_block1{
    max-width: 700px;
    font-size: 14px;
    letter-spacing: 0.4px;
}

.per_block2{
    max-width: 100px;
    margin-left: 30px;
}

.Mui-checked {
    color: #0C768A !important;
}

.css-priqlz-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #0C768A !important;
}